import queryString from 'query-string';

const queryParams =
  typeof window !== 'undefined'
    ? queryString.parse(window.location.search)
    : {};

export default function queryParam(name) {
  if (!queryParams[name]) {
    return '';
  }

  if (Array.isArray(queryParams[name])) {
    return queryParams[name][0];
  }

  return queryParams[name];
}
