/* eslint-disable import/prefer-default-export */
import smoothscroll from 'smoothscroll-polyfill';

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

export const scrollParent = () => {
  if ('parentIFrame' in window) {
    window.parentIFrame.sendMessage({ type: 'scrollToTop' });
  }
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });

  scrollParent();
};
