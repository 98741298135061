import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

const environment = process.env.GATSBY_SENTRY_ENVIRONMENT;
const isProd = environment === 'production';
const tracesSampleRate = isProd ? 0.02 : 0.2;

// https://docs.sentry.io/platforms/javascript/guides/gatsby/performance/instrumentation/automatic-instrumentation/#tracingorigins

const initialize = () => {
  // https://docs.sentry.io/platforms/javascript/guides/gatsby/#sentry-configuration-file
  Sentry.init({
    dsn:
      'https://bfaf93d3fa96463f8a9cbd0fcf91dedf@o335887.ingest.sentry.io/2127359',
    environment,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    tracesSampleRate,
  });

  if (!isProd) {
    // eslint-disable-next-line no-console
    console.log(`Sentry initialized for the ${environment} environment`);
  }
};

if (environment) {
  initialize();
}
