const updateFieldsDefault = {
  action_id: '',
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
};

const updateFieldsReducers = (state = updateFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'UPDATE_UPDATE_FIELDS':
      return { ...state, ...action.updateFields };
    case 'UPDATE_RESET_FIELDS':
      return { ...updateFieldsDefault };
    default:
      return state;
  }
};

export default updateFieldsReducers;
