const leoFieldsDefault = {
  state: '',
  region: '',
};

const leoFieldsReducers = (state = leoFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'LEOFIELDS_SET_STATE':
      return { ...state, state: action.state, region: '' };
    case 'LEOFIELDS_SET_REGION':
      return { ...state, region: action.region };
    case 'LEOFIELDS_RESET':
      return { ...leoFieldsDefault };
    default:
      return state;
  }
};

export default leoFieldsReducers;
