const futurevoterFieldsDefault = {
  action_id: '',
  uuid: '',
  sms_opt_in_voteamerica: true,
  age: null,
  ovr_link: '',
  preregistration_available: false,
  meets_age_minimum: false,
  will_be_18_by_next_election: false,
  registration_eligibility_date: null,
  formRequested: false,
  submitMethod: null,
};

const futurevoterFieldsReducers = (
  state = futurevoterFieldsDefault,
  action = {}
) => {
  switch (action.type) {
    case 'FUTUREVOTER_UPDATE_FIELDS':
      return { ...state, ...action.futurevoterFields };
    case 'FUTUREVOTER_RESET_FIELDS':
      return { ...futurevoterFieldsDefault };
    case 'FUTUREVOTER_SUBMIT_METHOD':
      return { ...state, submitMethod: action.submitMethod };
    default:
      return state;
  }
};

export default futurevoterFieldsReducers;
