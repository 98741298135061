import * as Sentry from '@sentry/gatsby';
import updateDataLayer from '../utils/events';
import axios from '../utils/axios';
import allowedFreeSubscribers from '../utils/allowedFreeSubscribers';

const subscriberThunk = () => (dispatch, getState) => {
  const { subscriber, subscriberData } = getState().sourcing;
  const isSubscriberAlwaysAllowed = allowedFreeSubscribers.includes(subscriber);

  if (isSubscriberAlwaysAllowed) {
    dispatch({
      type: 'SOURCING_UPDATE',
      sourcing: {
        hasSubscriberLoaded: true,
      },
    });
  } else if (typeof subscriberData === 'undefined') {
    return axios
      .get(`subscriber/id/${subscriber}/`)
      .then(({ data }) => {
        const sourcingUpdate = {
          subscriberData: data,
          hasSubscriberLoaded: true,
        };

        /* We want to store all the subscriber data in the state for access in disclaimers */
        dispatch({
          type: 'SOURCING_UPDATE',
          sourcing: sourcingUpdate,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          updateDataLayer({
            event: 'invalid-subscriber-id',
          });
          // We have subscriber data in that we know the subscriber is invalid,
          // so we want to move on from the spinner to the "subscription expired" page
          dispatch({
            type: 'SOURCING_UPDATE',
            sourcing: {
              hasSubscriberLoaded: true,
            },
          });
        } else {
          /* eslint-disable-next-line no-console */
          console.warn(error?.response?.data ?? error);
          Sentry.captureException(error);
        }
      });
  }

  return Promise.resolve();
};

export default subscriberThunk;
