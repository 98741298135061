/**
 * Submits an event to Google Tag Manager, which we use to track website analytics and Google Ads conversions.
 */
const pushEventToGoogleTagManager = (obj) => {
  window.dataLayer = window.dataLayer ? window.dataLayer : [];
  window.dataLayer.push(obj);
};

/**
 * Submits an event through IFrame Resizer, which is used to resize embedded iframes to fit tool content.
 */
const sendEventThroughIframeResizer = (obj) => {
  if ('parentIFrame' in window) {
    window.parentIFrame.sendMessage({ type: 'parentMessage', payload: obj });
  }
};

/**
 * Submits an event to VWO, our A/B testing platform. Should be used to track events we consider to be "conversions".
 */
const postEventToVwo = (obj) => {
  if (!['action-start', 'action-finish'].includes(obj.event)) {
    return;
  }

  const eventData = {
    tool: obj.tool,
  };

  if (obj.method) {
    eventData.method = obj.method;
  }

  window.VWO = window.VWO || [];
  window.VWO.event =
    window.VWO.event ||
    // eslint-disable-next-line func-names
    function () {
      // eslint-disable-next-line prefer-rest-params
      window.VWO.push(['event'].concat([].slice.call(arguments)));
    };

  window.VWO.event(obj.event, eventData);
};

const updateDataLayer = (obj) => {
  if (typeof window === 'undefined') {
    return;
  }

  const eventData = obj;

  // exclude PII from FutureVoter events to protect the privacy of <18
  if (eventData.tool && eventData.tool === 'futurevoter') {
    eventData.last_name = undefined;
    eventData.email = undefined;
  }

  pushEventToGoogleTagManager(eventData);
  sendEventThroughIframeResizer(eventData);
  postEventToVwo(eventData);
};

export default updateDataLayer;
