/**
 * Converts a phone number to a human-readable representation in US-local
 * formatting.
 *
 * If the phone number is invalid, it will be returned as-is
 * and a warning will be logged.
 */
export default function phoneToE164(phone) {
  // Some implementations of matchAll return each match as an array; use
  // toString() to normalize to a string
  let digits = phone.match(/\d/g);

  if (digits.length === 11 && digits[0] === '1') {
    digits = digits.slice(1);
  }

  if (digits.length !== 10) {
    // eslint-disable-next-line no-console
    console.warn('Invalid phone number', phone);
    return phone;
  }

  return `(${digits.slice(0, 3).join('')}) ${digits
    .slice(3, 6)
    .join('')}-${digits.slice(6, 10).join('')}`;
}
