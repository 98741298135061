import { showTopAlert } from '../utils/displayOptions';

const siteConfigDefault = {
  showDistractions: true,
  showLocateText: true,
  isEmbed: false,
  showTopAlert,
};

const siteConfigReducers = (state = siteConfigDefault, action = {}) => {
  switch (action.type) {
    case 'SITECONFIG_SHOW_DISTRACTIONS':
      return { ...state, showDistractions: true };
    case 'SITECONFIG_HIDE_DISTRACTIONS':
      return { ...state, showDistractions: false };
    case 'SITECONFIG_SHOW_LOCATE_TEXT':
      return { ...state, showLocateText: true };
    case 'SITECONFIG_HIDE_LOCATE_TEXT':
      return { ...state, showLocateText: false };
    case 'SITECONFIG_IS_EMBED':
      return { ...state, isEmbed: true };
    case 'SITECONFIG_SHOW_TOP_ALERT':
      return { ...state, showTopAlert: true };
    case 'SITECONFIG_HIDE_TOP_ALERT':
      return { ...state, showTopAlert: false };
    default:
      return state;
  }
};

export default siteConfigReducers;
