const registerFieldsDefault = {
  has_mailing_address: false,
  mailing_address1: '',
  mailing_address2: '',
  mailing_city: '',
  mailing_state: '',
  mailing_zipcode: '',
  state_id_number: '',
  race_ethnicity: '',
  has_previous_name: false,
  has_previous_address: false,
  previous_title: '',
  previous_first_name: '',
  previous_last_name: '',
  previous_suffix: '',
  previous_address1: '',
  previous_address2: '',
  previous_city: '',
  previous_state: '',
  previous_zipcode: '',
  is_18_or_over: null,
  uuid: '',
  action_id: '',
  matched_region: null,
  formRequested: false,
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  referring_tool: '',
  ovr_link: null,
  region: null,
  regionMatch: null,
  submitMethod: null,
};

const registerFieldsReducers = (state = registerFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'REGISTER_UPDATE_FIELDS':
      return { ...state, ...action.registerFields };
    case 'REGISTER_RESET_FIELDS':
      return { ...registerFieldsDefault };
    case 'REGISTER_SUBMIT_METHOD':
      return { ...state, submitMethod: action.submitMethod };
    default:
      return state;
  }
};

export default registerFieldsReducers;
