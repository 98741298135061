const upcomingFieldsDefault = {
  action_id: '',
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  elections: null,
  lookup_type: null,
  address: null,
};

const upcomingFieldsReducers = (state = upcomingFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'UPCOMING_UPDATE_FIELDS':
      return { ...state, ...action.upcomingFields };
    case 'UPCOMING_RESET_FIELDS':
      return { ...upcomingFieldsDefault };
    default:
      return state;
  }
};

export default upcomingFieldsReducers;
