const locateFieldsDefault = {
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
};

const locateFieldsReducers = (state = locateFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'LOCATE_UPDATE_FIELDS':
      return { ...state, ...action.locateFields };
    case 'LOCATE_RESET_FIELDS':
      return { ...locateFieldsDefault };
    default:
      return state;
  }
};

export default locateFieldsReducers;
