import * as Sentry from '@sentry/gatsby';
import queryparams from '../utils/queryparams';

const sourcingDefault = {
  utm_campaign: queryparams('utm_campaign'),
  utm_source: queryparams('utm_source'),
  utm_medium: queryparams('utm_medium'),
  utm_term: queryparams('utm_term'),
  utm_content: queryparams('utm_content'),
  source: queryparams('source'),
  subscriber: queryparams('subscriber') || 'voteamerica',
  edition: queryparams('edition'),
  email_referrer: queryparams('email_referrer'),
  mobile_referrer: queryparams('mobile_message_referrer'),
  subscriberData: undefined,
};

if (Sentry) {
  Sentry.setExtra('sourcing', sourcingDefault);
}

const sourcingReducers = (state = sourcingDefault, action = {}) => {
  switch (action.type) {
    case 'SOURCING_UPDATE':
      return { ...state, ...action.sourcing };
    case 'SOURCING_RESET':
      return { ...sourcingDefault };
    default:
      return state;
  }
};

export default sourcingReducers;
