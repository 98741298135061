import phoneToE164 from '~utils/phoneToHumanReadable';
import queryparams from '../utils/queryparams';

const personDefault = {
  title: queryparams('title'),
  first_name: queryparams('first_name'),
  last_name: queryparams('last_name'),
  suffix: queryparams('suffix'),
  address1: queryparams('address1'),
  address2: queryparams('address2'),
  city: queryparams('city'),
  state: queryparams('state'),
  zipcode: queryparams('zipcode'),
  month_of_birth: queryparams('month_of_birth'),
  day_of_birth: queryparams('day_of_birth'),
  year_of_birth: queryparams('year_of_birth'),
  email: queryparams('email'),
  phone: queryparams('phone') ? phoneToE164(queryparams('phone')) : '',
};

const personReducers = (state = personDefault, action = {}) => {
  switch (action.type) {
    case 'PERSON_UPDATE':
      // NOTE: do not dispatch this event manually. Use the thunk in actions/updatePersonFields
      // so that the state fields stay in sync.
      return { ...state, ...action.person };
    case 'PERSON_RESET':
      return { ...personDefault };
    default:
      return state;
  }
};

export default personReducers;
