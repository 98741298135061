const pledgeFieldsDefault = {
  action_id: '',
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
  registered: null,
  voter_status: null,
  address: null,
  matched_first_name: null,
  matched_last_name: null,
  last_update_date: null,
};

const pledgeFieldsReducers = (state = pledgeFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'PLEDGE_UPDATE_FIELDS':
      return { ...state, ...action.pledgeFields };
    case 'PLEDGE_RESET_FIELDS':
      return { ...pledgeFieldsDefault };
    default:
      return state;
  }
};

export default pledgeFieldsReducers;
