const remindFieldsDefault = {
  action_id: '',
  sms_opt_in_subscriber: true,
  sms_opt_in_voteamerica: true,
};

const remindFieldsReducers = (state = remindFieldsDefault, action = {}) => {
  switch (action.type) {
    case 'REMIND_UPDATE_FIELDS':
      return { ...state, ...action.remindFields };
    case 'REMIND_RESET_FIELDS':
      return { ...remindFieldsDefault };
    default:
      return state;
  }
};

export default remindFieldsReducers;
