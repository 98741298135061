/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { devToolsEnhancer } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/gatsby';
import rootReducer from '.';
import observeStore from './observeStore';

const enhancers = compose(applyMiddleware(thunk), devToolsEnhancer());

export const store = createStore(rootReducer, enhancers);

function StoreProvider({ element }) {
  // Sync sourcing to Sentry
  useEffect(() =>
    observeStore(
      store,
      (state) => state.sourcing,
      (newSourcing) => {
        if (newSourcing && Sentry) {
          Sentry.setExtra('sourcing', newSourcing);
          Sentry.setTag('subscriber', newSourcing.subscriber);
        }
      }
    )
  );

  return <Provider store={store}>{element}</Provider>;
}

StoreProvider.propTypes = {
  element: PropTypes.element,
};

function AppProvider({ element }) {
  return <StoreProvider element={element} />;
}

if (typeof window !== 'undefined' && window.Cypress) {
  window.store = store;
}

AppProvider.propTypes = {
  element: PropTypes.element,
};

export default AppProvider;
