import axios from 'axios';
import axiosRetry from 'axios-retry';

const client = axios.create({ baseURL: process.env.GATSBY_API_URL });

// Set up a retry policy: we retry up to 3 times if we receive a 5xx error or
// network error on an idempotent request (GET, PUT, or DELETE)
axiosRetry(client, { retries: 3 });

export default client;

const infoAPIClient = axios.create({
  baseURL: process.env.GATSBY_INFO_API_URL || 'https://api.voteamerica.org/v2/',
});
axiosRetry(client, { retries: 3 });
export { infoAPIClient };
